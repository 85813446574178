// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("bootstrap-datepicker")
require("@fortawesome/fontawesome-free/js/all")
require("../stylesheets/application")

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.datepicker').datepicker();

  $("#domain_secure").click(function(){
    $(this).button('toggle');
  });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { cocoonInitializer } from 'cocoon/app/assets/javascripts/cocoon'

// to initialize cocoon ...
cocoonInitializer($, jQuery)

$(document).on('cocoon:after-insert', () => {
    // ...
})

require("trix")
require("@rails/actiontext")
